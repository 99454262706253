export let menuDataMembers = [
  {
    path: '/seus-planejamentos',
    title: 'Planejamentos',
    icon: 'ti-receipt',
    defaultActive: true,
    active: true,
    type: 'link'
  }
]
