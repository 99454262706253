<template>
  <header class="app-header">
    <div class="main-header-container container-fluid">
      <div class="header-content-left">
        <div class="header-element">
          <div class="horizontal-logo">
            <router-link
              class="header-logo"
              to="/"
            >
              <img
                src="/images/brand/logotipo/logo.png"
                alt="logo"
                class="desktop-logo"
              >
              <img
                src="/images/brand/logotipo/logo.png"
                alt="logo"
                class="toggle-logo"
              >
              <img
                src="/images/brand/logotipo/logo-white.png"
                alt="logo"
                class="desktop-dark"
              >
              <img
                src="/images/brand/logotipo/logo-white.png"
                alt="logo"
                class="toggle-dark"
              >
              <img
                src="/images/brand/logotipo/logo-white.png"
                alt="logo"
                class="desktop-white"
              >
              <img
                src="/images/brand/logotipo/logo-white.png"
                alt="logo"
                class="toggle-white"
              >
            </router-link>
          </div>
        </div>

        <div class="header-element">
          <a
            @click="ToggleMenu"
            aria-label="Hide Sidebar"
            class="sidemenu-toggle header-link animated-arrow hor-toggle"
            href="javascript:void(0);"
          ><span></span></a>
        </div>
      </div>

      <div class="header-content-right">
        <!-- <div class="header-element header-theme-mode">
          <a
            href="javascript:void(0);"
            class="header-link layout-setting"
          >
            <span
              class="light-layout"
              @click="colorthemeFn('dark')"
            >
              <i class="fe fe-moon header-link-icon lh-2"></i>
            </span>
            <span
              class="dark-layout"
              @click="colorthemeFn('light')"
            >
              <i class="fe fe-sun header-link-icon lh-2"></i>
            </span>
          </a>
        </div>

        <div class="header-element cart-dropdown d-xl-flex d-none">
          <a
            href="javascript:void(0);"
            class="header-link dropdown-toggle"
            data-bs-auto-close="outside"
            data-bs-toggle="dropdown"
          >
            <i class="fe fe-shopping-cart header-link-icon"></i>
            <span
              class="badge bg-primary rounded-pill header-icon-badge"
              id="cart-icon-badge"
            >{{ cartList.length
              }}</span>
          </a>
          <div
            class="main-header-dropdown dropdown-menu dropdown-menu-end"
            data-popper-placement="none"
          >
            <div class="p-3">
              <div class="d-flex align-items-center justify-content-between">
                <p class="mb-0 fs-17 fw-semibold">Produtos Selecionados</p>
                <span
                  class="badge bg-primary rounded-pill"
                  id="cart-data"
                >{{ cartList.length }} Items</span>
              </div>
            </div>
            <div>
              <hr class="dropdown-divider">
            </div>
            <ul
              v-if="cartList.length"
              class="list-unstyled mb-0"
              id="header-cart-items-scroll"
            >
              <li
                v-for="(item, key) in cartList"
                :key=key
                class="dropdown-item"
              >
                <div class="d-flex align-items-center cart-dropdown-item">
                  <img
                    v-if="item.img"
                    :src="item.img"
                    alt="img"
                    class="avatar avatar-sm br-5 me-3"
                  >
                  <div class="flex-grow-1">
                    <div class="d-flex align-items-start justify-content-between mb-0">
                      <div class="mb-0 fs-13 text-dark fw-medium">
                        <router-link
                          :to="`#`"
                          class="text-dark"
                        >{{ item.title }}</router-link>
                      </div>
                      <div>
                        <span class="text-black mb-1 fw-medium">${{ item.price }}</span>
                      </div>
                    </div>
                    <div class="min-w-fit-content d-flex align-items-start justify-content-between">
                      <ul class="header-product-item d-flex">
                        <li
                          v-for="(cat, index) in item.categoryList"
                          :key="index"
                        >
                          <span :class="cat.valueBgColor ? `badge ${cat.valueBgColor} fs-10` : ''">
                            {{ cat.value }}
                          </span>
                          <template v-if="cat.qty">Quantidade: {{ cat.qty }}</template>
<template v-if="cat.status">Status: <span :class="cat.statusBgColor">{{ cat.status }}</span>
                          </template>
</li>
</ul>
<div class="ms-auto">
  <a @click="removeItemFromCart(item)" href="javascript:void(0);"
    class="header-cart-remove float-end dropdown-item-close"><i class="ri-delete-bin-2-line"></i></a>
</div>
</div>
</div>
</div>
</li>
</ul>
<div v-if="cartList.length" class="p-3 empty-header-item border-top">
  <div class="d-grid">
    <router-link :to="`#`" class="btn btn-primary">Pagar Agora</router-link>
  </div>
</div>
<div v-if="!cartList.length" class="p-5 empty-item">
  <div class="text-center">
    <span class="avatar avatar-xl avatar-rounded bg-warning-transparent">
      <i class="ri-shopping-cart-2-line fs-2"></i>
    </span>
    <h6 class="fw-bold mb-1 mt-3">Nenhum produto selecionado</h6>
    <span class="mb-3 fw-normal fs-13 d-block">Adicione alguns items para ficar feliz :)</span>
    <router-link :to="`#`" class="btn btn-primary btn-wave btn-sm m-1" data-abc="true">
      Veja os produtos <i class="bi bi-arrow-right ms-1"></i></router-link>
  </div>
</div>
</div>
</div> -->

        <!-- <div class="header-element notifications-dropdown">
          <a
            href="javascript:void(0);"
            class="header-link dropdown-toggle"
            data-bs-toggle="dropdown"
            data-bs-auto-close="outside"
            id="messageDropdown"
            aria-expanded="false"
          >
            <i class="fe fe-bell header-link-icon"></i>
            <span
              class="badge bg-secondary rounded-pill header-icon-badge pulse pulse-secondary"
              id="notification-icon-badge"
            >
              {{ notificationList.length }}
            </span>
          </a>
          <div
            class="main-header-dropdown dropdown-menu dropdown-menu-end"
            data-popper-placement="none"
          >
            <div class="p-3">
              <div class="d-flex align-items-center justify-content-between">
                <p class="mb-0 fs-17 fw-semibold">Notificações</p>
                <span
                  class="badge bg-secondary rounded-pill"
                  id="notifiation-data"
                >{{ notificationList.length }}
                  Não lida</span>
              </div>
            </div>
            <div class="dropdown-divider"></div>
            <simplebar data-simplebar-auto-hide="false">
              <ul
                v-if="notificationList.length"
                class="list-unstyled mb-0"
                id="header-notification-scroll"
              >
                <li
                  v-for="(item, key) in notificationList"
                  :key=key
                  class="dropdown-item"
                >
                  <div class="d-flex align-items-start">
                    <div class="pe-2">
                      <span
                        v-if="item.icon"
                        class="avatar avatar-md bg-primary-transparent br-5"
                        :class="item.isOnline ? 'online' : 'offline'"
                      >
                        <img
                          alt="avatar"
                          :src="item.icon"
                        >
                      </span>
                    </div>
                    <div class="flex-grow-1 d-flex align-items-center justify-content-between">
                      <div>
                        <p class="mb-0">
                          <router-link
                            :to="`#`"
                            class="text-dark"
                          >{{ item.title }}
                          </router-link>
                        </p>
                        <span class="text-muted fw-normal fs-12 header-notification-text">{{ item.time }}</span>
                      </div>
                      <div>
                        <a
                          @click=removeNotification(item)
                          href="javascript:void(0);"
                          class="min-w-fit-content text-muted me-1 dropdown-item-close1"
                        ><i class="ti ti-x fs-16"></i></a>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </simplebar>
            <div
              v-if="!notificationList.length"
              class="p-5 empty-item1"
            >
              <div class="text-center">
                <span class="avatar avatar-xl avatar-rounded bg-secondary-transparent">
                  <i class="ri-notification-off-line fs-2"></i>
                </span>
                <h6 class="fw-semibold mt-3">Nenhuma notificação</h6>
              </div>
            </div>
          </div>
        </div> -->

        <div class="header-element">
          <a
            href="javascript:void(0);"
            class="header-link dropdown-toggle"
            id="mainHeaderProfile"
            data-bs-toggle="dropdown"
            data-bs-auto-close="outside"
            aria-expanded="false"
          >
            <div class="d-flex align-items-center">
              <div class="header-link-icon">
                <i class="fe fe-settings header-link-icon"></i>
              </div>
            </div>
          </a>
          <ul
            class="main-header-dropdown dropdown-menu pt-0 overflow-hidden header-profile-dropdown dropdown-menu-end"
            aria-labelledby="mainHeaderProfile"
          >
            <li v-if="userLogged">
              <div class="header-navheading border-bottom">
                <h6 class="main-notification-title text-uppercase">{{ userLogged }}</h6>
              </div>
            </li>
            <!-- <li>
              <router-link
                class="dropdown-item d-flex border-bottom"
                :to="`#`"
              >
                <i class="fe fe-user fs-16 align-middle me-2"></i>Minha conta
              </router-link>
            </li>
            <li><router-link
                class="dropdown-item d-flex border-bottom"
                :to="`#`"
              >
                <i class="fe fe-headphones fs-16 align-middle me-2"></i>Support
              </router-link>
            </li> -->
            <li>
              <router-link
                class="dropdown-item d-flex"
                :to="{ name: 'signin' }"
              ><i class="fe fe-power fs-16 align-middle me-2"></i>Sair
              </router-link>
            </li>
          </ul>
        </div>
      </div>
    </div>

  </header>
</template>

<script>
import Selectoptions from "@/shared/UI/selectoptions.vue";
import { switcherStore } from '@/stores/switcher';
import { notificationList, cartList } from '@/data/headerdata.js';

export default {
  components: {
    Selectoptions
  },
  setup() {
    const switcher = switcherStore();

    const colorthemeFn = value => {
      localStorage.setItem('spruhacolortheme', value); switcher.colorthemeFn(value);
      localStorage.removeItem('spruhabodylightRGB')
      localStorage.removeItem('spruhabodyBgRGB')
    };

    return {
      switcher,
      colorthemeFn,
    }
  },
  data() {
    return {
      notificationList, cartList,
      isFullScreen: false,
      value: null,
      options: ["Firefox", "Chrome", "Safari", "Opera", "Internet Explorer"],
      url: import.meta.env.BASE_URL,
      userLogged: null
    };
  },
  created() {
    this.userLogged = localStorage.getItem('x-user-logged');

    window.addEventListener("scroll", this.handleScroll);
    this.handleScroll();
  },
  unmounted() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    ToggleMenu() {
      let html = document.querySelector('html');
      if (window.innerWidth <= 992) {
        let dataToggled = html.getAttribute('data-toggled');
        if (dataToggled == 'open') {
          html.setAttribute('data-toggled', 'close');
        } else {
          html.setAttribute('data-toggled', 'open');
        }
      } else {
        let menuNavLayoutType = html.getAttribute('data-nav-style');
        let verticalStyleType = html.getAttribute('data-vertical-style');
        if (menuNavLayoutType) {
          let dataToggled = html.getAttribute('data-toggled');
          if (dataToggled) {
            html.removeAttribute('data-toggled');
          } else {
            html.setAttribute('data-toggled', menuNavLayoutType + '-closed');
          }
        } else if (verticalStyleType) {
          let dataToggled = html.getAttribute('data-toggled');
          if (verticalStyleType == 'doublemenu') {
            if (html.getAttribute('data-toggled') === 'double-menu-open' && document.querySelector('.double-menu-active')) {
              html.setAttribute('data-toggled', 'double-menu-close');
            } else {
              if (document.querySelector('.double-menu-active')) {
                html.setAttribute('data-toggled', 'double-menu-open');
              }
            }
          } else if (dataToggled) {
            html.removeAttribute('data-toggled');
          } else {
            switch (verticalStyleType) {
              case 'closed':
                html.setAttribute('data-toggled', 'close-menu-close');
                break;
              case 'icontext':
                html.setAttribute('data-toggled', 'icon-text-close');
                break;
              case 'overlay':
                html.setAttribute('data-toggled', 'icon-overlay-close');
                break;
              case 'detached':
                html.setAttribute('data-toggled', 'detached-close');
                break;
            }
          }
        }
      }
    },
    toggleFullScreen() {
      const element = document.documentElement; // Full-screen target element

      if (document.fullscreenElement || document.mozFullScreenElement || document.webkitFullscreenElement) {
        // If in full-screen mode, exit it
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.webkitExitFullscreen) {
          document.webkitExitFullscreen();
        }
      } else {
        // If not in full-screen mode, enter it
        if (element.requestFullscreen) {
          element.requestFullscreen();
        } else if (element.mozRequestFullScreen) {
          element.mozRequestFullScreen();
        } else if (element.webkitRequestFullscreen) {
          element.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
        }
      }
    },
    removeNotification(item) {
      this.notificationList = this.notificationList.filter(itm => itm.id != item.id);
    },
    removeItemFromCart(item) {
      this.cartList = this.cartList.filter(itm => itm.id != item.id);
    },
    fullscreenchanged() {
      if (document.fullscreenElement) {
        this.isFullScreen = true;
      } else {
        this.isFullScreen = false;
      }
    },
    //sticky-pin
    handleScroll() {
      if (window.scrollY > 30) {
        let Scolls = document.querySelectorAll(".sticky");
        Scolls.forEach((e) => {
          e.classList.add("sticky-pin");
        });
      } else {
        let Scolls = document.querySelectorAll(".sticky");
        Scolls.forEach((e) => {
          e.classList.remove("sticky-pin");
        });
      }
    }
  },
  mounted() {
    document.addEventListener("fullscreenchange", this.fullscreenchanged);
  },
};
</script>
