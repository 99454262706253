<template>
  <component :is="layout">
    <router-view />
  </component>
</template>

<script>
import LayoutPartner from '@/core/layouts/LayoutPartner.vue'
import LayoutMember from '@/core/layouts/LayoutMember.vue'
import LayoutEmpty from '@/core/layouts/LayoutEmpty.vue'
import LayoutAdmin from '@/core/layouts/LayoutAdmin.vue'

export default {
  name: 'StartedApp',
  components: {
    partner: LayoutPartner,
    member: LayoutMember,
    admin: LayoutAdmin,
    empty: LayoutEmpty,
  },
  computed: {
    layout() {
      return this.$route?.meta?.layout ?? 'empty'
    }
  }
}
</script>