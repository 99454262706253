export let menuDataBO = [
  {
    path: `/admin/dashboard`,
    title: 'Dashboard',
    type: 'link',
    icon: 'ti-home',
    active: true,
    defaultActive: false
  },
  {
    path: `/admin/customers`,
    title: 'Clientes',
    type: 'link',
    icon: 'ti-user',
    active: false,
    defaultActive: false
  },
  {
    path: `/admin/categories`,
    title: 'Categorias',
    type: 'link',
    icon: 'ti-link',
    active: false,
    defaultActive: false
  },
  {
    path: `/admin/products`,
    title: 'Produtos',
    type: 'link',
    icon: 'ti-shopping-cart-full',
    active: false,
    defaultActive: false
  },
  {
    path: `/admin/plataform-terms`,
    title: 'Termos de uso',
    type: 'link',
    icon: 'ti-files',
    active: false,
    defaultActive: false
  },
  {
    path: `/admin/verticals`,
    title: 'Configurações',
    type: 'link',
    icon: 'ti-settings',
    active: false,
    defaultActive: false
  }
]
